import { Element } from 'react-scroll'; // Import Element from react-scroll
import WhatsAppIcon from '@mui/icons-material/WhatsApp';
import LocalPhoneOutlinedIcon from '@mui/icons-material/LocalPhoneOutlined';
import Maestro from './Maestro.png'; 
import ApplePay from './ApplePay.png'; 
import Visa from './Visa.png'; 
import MasterCard from './MasterCard.png'; 
import Cash from './Cash.png'; 
import { useMediaQuery } from 'react-responsive';







const General = ({id}) => {
  const isMobile = useMediaQuery({ maxWidth: 1200 });
  return (
    <Element name={id}> {/* Wrap the entire content with Element and assign name prop */}
      <div  className="ContainerGeneral">
        <div className="SubContainerGeneral">
          <div className="GeneralHeader">
            <p>On-demand Service</p>
          </div>
          <div className="text1">
            <p>For short notice rides, please contact us by phone. </p>
            
          </div>
          <div className="WhatsappIcon">
          </div>
          <div className="PhoneIcon">
          </div>
          <div className="text2">
            <a href="tel:+393429118790" className="no-link-style">+39 3429118790</a> 
            <div className="icons">
            <WhatsAppIcon style={{ fontSize: isMobile ? 70 : 35 , color:'green'}} />  
            <LocalPhoneOutlinedIcon style={{ fontSize: isMobile ? 70 : 35, marginLeft:30, color:'green'}} />  
            </div>
            <div className="payment">
            <p>Accepted Payments:</p>
            <div className="paymentMethods">
            <img src={Maestro} alt="Visa" style={{ width: 50, height: 50, marginLeft:10 }} /> {/* Adjust path as necessary */}
            <img src={Visa} alt="Visa" style={{ width: 50, height: 50,marginLeft:10 }} /> {/* Adjust path as necessary */}
            <img src={MasterCard} alt="Visa" style={{ width: 70, height: 70, marginLeft:10 , marginBottom:-10}} /> {/* Adjust path as necessary */}
            <img src={ApplePay} alt="Visa" style={{ width: 50, height: 50, marginLeft:10 }} /> {/* Adjust path as necessary */}
            <img src={Cash} alt="Cash" style={{ width: 30, height: 30, marginLeft:10, marginBottom:13 }} /> {/* Adjust path as necessary */}
            </div>

            </div>
          </div>
        </div>
      </div>
    </Element>
  );
};

export default General;