import * as React from 'react';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import Menu from '@mui/material/Menu';
import MenuIcon from '@mui/icons-material/Menu';
import Container from '@mui/material/Container';
import Button from '@mui/material/Button';
import MenuItem from '@mui/material/MenuItem';
import Logo from './logo.png';
import { Link } from 'react-scroll'; // Import Link from react-scroll
import { useTranslation } from 'react-i18next'; // Import useTranslation
import LanguageIcon from '@mui/icons-material/Language';
import { useMediaQuery } from 'react-responsive';
import Cookies from 'js-cookie';

const pages = ['Home','About Us', 'On-Demand', 'Booking', 'Faq', 'Contact']; // Update page names to match section IDs
const languages = [
  { code: 'en', label: 'English' },
  { code: 'de', label: 'Deutsch' },
  { code: 'it', label: 'Italiano' }
];

function ResponsiveAppBar() {
  const [anchorElNav, setAnchorElNav] = React.useState(null);
  const [anchorElLang, setAnchorElLang] = React.useState(null);
  const { i18n } = useTranslation();
  const isMobile = useMediaQuery({ maxWidth: 1200 });


  React.useEffect(() => {
    const userConsent = Cookies.get('userConsent');
    if (userConsent === 'accepted') {
      const savedLanguage = Cookies.get('language');
      if (savedLanguage) {
        i18n.changeLanguage(savedLanguage);
      }
    }
  }, [i18n]);

  const handleOpenNavMenu = (event) => {
    setAnchorElNav(event.currentTarget);
  };

  const handleCloseNavMenu = () => {
    setAnchorElNav(null);
  };

  const handleOpenLangMenu = (event) => {
    setAnchorElLang(event.currentTarget);
  };

  const handleCloseLangMenu = () => {
    setAnchorElLang(null);
  };

  const handleSetLanguage = (langCode) => {
    i18n.changeLanguage(langCode);
    Cookies.set('language', langCode, { expires: 7 });
    handleCloseLangMenu();
  };

  return (
    <AppBar position="sticky"
      sx={{
        px: 2,
        backgroundColor: '#023e48',
        fontFamily: 'DM Sans, sans-serif',
        height: isMobile ? '140px' : '80px'
      }}>
      <Container maxWidth="xl">
        <Toolbar disableGutters>
          <Box sx={{
            flexGrow: 1,
            display: 'flex',
            justifyContent: { xs: 'flex-start', md: 'center' },
            alignItems: 'center'
          }}>
            <img src={Logo} alt="Logo" style={{ height: isMobile ? 120 : 70, width: isMobile ? 160 : 90, marginTop: 5 }} />
            <span style={{ color: 'cdb838', fontSize: isMobile ? '30px' : '18px', marginTop: 7, marginLeft: -10 }}>Taxi-Moroder</span>
          </Box>

          {/* Display the language icon always */}
          <IconButton
            sx={{ mx: 0, color: 'white',  marginLeft: isMobile ? 0 : 65, marginTop: 1 }}
            onClick={handleOpenLangMenu}
          >
            <LanguageIcon  sx={{ mx: 0, color: 'white',  fontSize: isMobile ? 60 : 25, marginTop: -0.6 }}/>
          </IconButton>
          <Menu
            id="menu-lang"
            anchorEl={anchorElLang}
            anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
            transformOrigin={{ vertical: 'top', horizontal: 'right' }}
            open={Boolean(anchorElLang)}
            onClose={handleCloseLangMenu}
          >
              {languages.map((lang) => (
              <MenuItem key={lang.code} onClick={() => handleSetLanguage(lang.code)} sx={{ fontSize: isMobile ? '40px' : 'inherit', py: isMobile ? 2 : 'inherit' }}>
                {lang.label}
              </MenuItem>
            ))}
          </Menu>

          {/* Display the menu links and hamburger menu */}
          {!isMobile && (
            <Box sx={{ flexGrow: 1, display: 'flex', justifyContent: 'flex-end', alignItems: 'center' }}>
              {pages.map((page) => (
                <Link style={{ marginTop: 5 }} key={page} to={page} smooth={true} offset={-95} duration={500}>
                  <Button sx={{ mx: 2, color: 'white', fontFamily: 'DM Sans, sans-serif' }}>{page}</Button>
                </Link>
              ))}
            </Box>
          )}

          {isMobile && (
            <Box sx={{ flexGrow: 0, display: 'flex' }}>
              <IconButton
                size="large"
                aria-label="dropdown"
                aria-controls="menu-appbar"
                aria-haspopup="true"
                onClick={handleOpenNavMenu}
                color="inherit"
              >
                <MenuIcon sx={{ fontSize: 80 }} />
              </IconButton>

            <Menu
            id="menu-appbar"
            anchorEl={anchorElNav}
            anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
            transformOrigin={{ vertical: 'top', horizontal: 'right' }}
            open={Boolean(anchorElNav)}
            onClose={handleCloseNavMenu}
          >
            {pages.map((page) => (
                  <Link key={page} to={page} smooth={true} duration={500}>
                    <MenuItem onClick={handleCloseNavMenu} sx={{ fontFamily: 'DM Sans, sans-serif',  fontSize: '40px', py: 2 }}>
                      {page}
                    </MenuItem>
                  </Link>
                ))}
          </Menu>
            </Box>
          )}
        </Toolbar>
      </Container>
    </AppBar>
  );
}

export default ResponsiveAppBar;