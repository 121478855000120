import React from 'react';
import interior from './Interior.jpeg';
import car from './Car.jpeg';


const pictures = [car,interior];
const delay = 6000;



const Slideshow = ({ id }) => {
    const [index, setIndex] = React.useState(0);
    const timeoutRef = React.useRef(null);

    function resetTimeout() {
        if (timeoutRef.current) {
          clearTimeout(timeoutRef.current);
        }
      }

    React.useEffect(() => {
        resetTimeout();
        timeoutRef.current = setTimeout(
          () =>
            setIndex((prevIndex) =>
              prevIndex === pictures.length - 1 ? 0 : prevIndex + 1
            ),
          delay
        );
    
        return () => {};
      }, [index]);



    return (
    <div id ={id} className="slideshow">
      <div className="slideshowSlider"
        style={{ transform: `translate3d(${-index * 100}%, 0, 0)` }}
        >
      {pictures.map((pic, index) => (
          <img className="slide" key={index} src={pic} alt={`Slide ${index}`} />
        ))}
      </div>
      <div className="slideshowDots">
        {pictures.map((_, idx) => (
          <div
          key={idx}
          className={`slideshowDot${index === idx ? " active" : ""}`}
          onClick={() => {
            setIndex(idx);
          }}
        ></div>
        ))}
      </div>
    </div>
  )
  }

  export default Slideshow;