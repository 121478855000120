import React, { useState, useEffect } from 'react';
import { TextField,Divider, FormControl,FormHelperText, InputLabel,Select, MenuItem, Grid, Button, Box, Checkbox,ListItemIcon, FormControlLabel,IconButton, Typography } from '@mui/material';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import PlaceOutlinedIcon from '@mui/icons-material/PlaceOutlined';
import CalendarMonthOutlinedIcon from '@mui/icons-material/CalendarMonthOutlined';
import AccessTimeOutlinedIcon from '@mui/icons-material/AccessTimeOutlined';
import Person2OutlinedIcon from '@mui/icons-material/Person2Outlined';
import { useNavigate } from 'react-router-dom';
import { countries } from 'countries-list';
import 'react-international-phone/style.css';
import { MuiPhone } from "./MuiPhone";
import axios from 'axios';
import EventSeatOutlinedIcon from '@mui/icons-material/EventSeatOutlined';
import DownhillSkiingOutlinedIcon from '@mui/icons-material/DownhillSkiingOutlined';
import PedalBikeOutlinedIcon from '@mui/icons-material/PedalBikeOutlined';
import CircularProgress from '@mui/material/CircularProgress';
import { useMediaQuery } from 'react-responsive';

const URL_PROXY = process.env.REACT_APP_SERVER

const countryOptions = Object.keys(countries).map((countryCode) => ({
  value: countryCode.toLowerCase(),
  label: countries[countryCode].name,
}));



const BookingForm = ({ bookingData }) => {

  const isMobile = useMediaQuery({ maxWidth: 1200 });

  const amenities = [
    { name: 'Child safety seat', icon: <EventSeatOutlinedIcon  style={{ fontSize: isMobile ? 55 : 25, marginRight: isMobile ? 10 : 0, marginBottom: isMobile ? 10 : 0}}/> },
    { name: 'Bikes', icon: <PedalBikeOutlinedIcon  style={{ fontSize: isMobile ? 55 : 25, marginRight: isMobile ? 10 : 0,  marginBottom: isMobile ? 10 : 0}}/> },
    { name: 'Skis', icon: <DownhillSkiingOutlinedIcon  style={{ fontSize: isMobile ? 55 : 25,  marginRight: isMobile ? 10 : 0, marginBottom: isMobile ? 10 : 0}}/> }
  ];

 

    //Define variables.
    const itemStyle = { fontSize: isMobile ? 40 : 20 }; // Adjust font size
    const iconStyles = { fontSize: isMobile ? 45 : 25};

    const [selectedAmenities, setSelectedAmenities] = useState([]);
    const [selectedCountry, setSelectedCountry] = useState('it');
    const [muiPhone, setMuiPhone] = useState('+39');
    const history = useNavigate();
    const [email, setEmail] = useState('');
    const [verifiedEmail, setVerifiedEmail] = useState('');
    const [firstName, setFirstName] = useState('');
    const [lastName, setLastName] = useState('');
    const [address, setAddress] = useState('');
    const [formErrors, setFormErrors] = useState({});
    const [additionalInfo, setAdditionalInfo] = useState('');
    const [AdressAddition, setAddressAddition] = useState('');

  const navigate = useNavigate();

  const [isSubmitting, setIsSubmitting] = useState(false);


  // Function to determine if bookingData is effectively empty
  const isEmptyData = (data) => {
    // Check if data is an object and has no meaningful data
    return data && Object.keys(data).length === 0;
  };


  useEffect(() => {
    if (!bookingData || isEmptyData(bookingData)) {
      console.log('No or empty booking data available, navigating to home.');
      navigate('/');
    }
  }, [bookingData, navigate]);
  if (!bookingData || isEmptyData(bookingData)) {
    return <CircularProgress />;
  }


  

  const {Date,DestinationAdress,OriginAdress,Persons,Time,price} = bookingData
  console.log(Date)
  console.log(Time)
  //const formattedTime = Time && Time.$d ? moment(Time.$d).format('HH:mm') : 'N/A';
  //const formattedDate = Date && Date.$d ? moment(Date.$d).format('DD-MM-YYYY') : 'N/A';


  const isAmenitySelected = (amenityName) => {
    console.log(amenityName)
    return selectedAmenities.includes(amenityName);
  };

  const handleAmenityToggle = (amenity) => () => {
    const currentIndex = selectedAmenities.indexOf(amenity);
    const newSelectedAmenities = [...selectedAmenities];

    if (currentIndex === -1) {
      newSelectedAmenities.push(amenity);
    } else {
      newSelectedAmenities.splice(currentIndex, 1);
    }
    console.log(newSelectedAmenities)
    setSelectedAmenities(newSelectedAmenities);
  };

  const handleBackClick = () => {
    const confirmLeave = window.confirm('Are you sure you want to leave? Your changes will be lost.');
    if (confirmLeave) {
      history('/'); // Redirect if the user confirms
    }
  };

   // Handle selection of country
  const handleCountryChange = (selectedOption) => {
    console.log(selectedOption)
    setSelectedCountry(selectedOption);
  };

  

  const validateForm = () => {

    let errors = {};
    if (!firstName.trim()) errors.firstName = "First name is required";
    if (!lastName.trim()) errors.lastName = "Last name is required";
    if (!address.trim()) errors.address = "Address is required";
    if (!email.trim()) {
      errors.email = "Email is required.";
    } else if (!email.includes('@')) {
      errors.email = "Not a valid Email.";
    }
    if (!selectedCountry.trim()) errors.selectedCountry = "Country is required";
    if (email !== verifiedEmail) errors.verifiedEmail = "Emails do not match";
    if (muiPhone.length < 6) errors.muiPhone = "Phone number must be at least 4 characters long";
    setFormErrors(errors);
    return Object.keys(errors).length === 0;
  };

  const handleSubmit = async (event) => {
    event.preventDefault();

  if (validateForm()) {
    setIsSubmitting(true);  
    try {
      const response = await axios.post(`${URL_PROXY}/requestBooking`, {
        key: process.env.REACT_APP_DATABASE_KEY,
        Date: Date,
        DestinationAdress,
        OriginAdress,
        Persons,
        Time: Time,
        price,
        selectedAmenities,
        selectedCountry,
        email,
        firstName,
        lastName,
        address,
        muiPhone,
        additionalInfo,
        AdressAddition
      });
      console.log('Success:', response.data);
      console.log(response.data);
      console.log('Booking ID:', response.data.bookingId);

      if (response.data.success && response.data.bookingId) {
        console.log('Booking ID:', response.data.bookingId);
        // Do something with the booking ID, like storing it, redirecting the user, etc.
        navigate('/bookingConfirmation', { state: { bookingId: response.data.bookingId } });
      } else {
        // Handle cases where booking creation might not return an ID
        console.error('Booking was not successful:', response.data.message);
      }
      // Redirect to Confirmation Page. do it over get request with ID or something. save id then bla bla....
    } catch (error) {
      console.error('Failed to submit booking data:', error);
      alert('An internal server error occurred. Please try again later.');
    }
    setIsSubmitting(false); 
  } else {
    console.error('Form is invalid, show errors');
  }
};


  return (
    <Box p={10} maxWidth="1000px" width="100%" margin="0 auto" style={{ paddingTop: '30px', paddingBottom: '0px' , marginTop: isMobile ? "5rem" : "0"}} >
     <IconButton
        aria-label="Back"
        onClick={handleBackClick}
        sx={{ position: 'absolute', top: 10, left: 10 , fontSize: '40px' }}

      >
        <ArrowBackIcon style={{fontSize: isMobile ? 70 : 25} } />
      </IconButton>
      <Typography variant="h4" gutterBottom style={{ fontSize: isMobile ? '5rem' : '2rem', color:"#023e48" }} >
        Personal Information
      </Typography>
      <Divider sx={{ my: 2 }} /> 
      <form onSubmit={handleSubmit}>
        <Grid container spacing={3}>
        <Grid item xs={12} lg={6} sx={{ marginTop: '20px' }} >
        <Box border={2} borderColor="#D3D3D3" borderRadius={4} p={2}sx={{boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.1)'}}>
          <Grid item xs={12} sx={{ paddingTop: '20px' }}>
          <TextField
            label="Firstname"
            fullWidth
            value={firstName} // Control the TextField with state
            onChange={(e) => setFirstName(e.target.value)} // Update state on change
            error={!!formErrors.firstName} // Show error state if there's an error for firstName
            helperText={formErrors.firstName} // Show helper text if there's an error
            InputProps={{
              style: { fontSize: isMobile ? '3rem' : '1rem', height: isMobile ? '7rem' : '3.5rem' }, // Adjust font size and height of the input
            }}
            InputLabelProps={{
              style: { fontSize: isMobile ? '3rem' : '1rem'}, // Adjust font size and height of the label
            }}
          />
          </Grid>
          <Grid item xs={12} sx={{ paddingTop: '20px' }}>
          <TextField
            label="Lastname"
            fullWidth
            value={lastName} // Control the TextField with state
            onChange={(e) => setLastName(e.target.value)} // Update state on change
            error={!!formErrors.lastName} // Show error state if there's an error for firstName
            helperText={formErrors.lastName} // Show helper text if there's an error
            InputProps={{
              style: { fontSize: isMobile ? '3rem' : '1rem', height: isMobile ? '7rem' : '3.5rem' }, // Adjust font size and height of the input
            }}
            InputLabelProps={{
              style: { fontSize: isMobile ? '3rem' : '1rem'}, // Adjust font size and height of the label
            }}
          />
          </Grid>
          <Grid item xs={12} sx={{ paddingTop: '20px' }}>
          <TextField
            label="Adress"
            fullWidth
            value={address} // Control the TextField with state
            onChange={(e) => setAddress(e.target.value)} // Update state on change
            error={!!formErrors.address} // Show error state if there's an error for firstName
            helperText={formErrors.address} // Show helper text if there's an error
            InputProps={{
              style: { fontSize: isMobile ? '3rem' : '1rem', height: isMobile ? '7rem' : '3.5rem' }, // Adjust font size and height of the input
            }}
            InputLabelProps={{
              style: { fontSize: isMobile ? '3rem' : '1rem'}, // Adjust font size and height of the label
            }}
          />
          </Grid>
          <Grid item xs={12} sx={{ paddingTop: '20px' }}>
            <TextField
              label="Additional Address details"
              fullWidth
              value={AdressAddition}
              onChange={(e) => setAddressAddition(e.target.value)}
              InputProps={{
                style: { fontSize: isMobile ? '3rem' : '1rem', height: isMobile ? '7rem' : '3.5rem' }, // Adjust font size and height of the input
              }}
              InputLabelProps={{
                style: { fontSize: isMobile ? '3rem' : '1rem'}, // Adjust font size and height of the label
              }}
            />
          </Grid>
          <Grid item xs={12} sx={{ paddingTop: '20px' }}>
            <FormControl fullWidth>
            <InputLabel shrink style={{ fontSize: isMobile ? '2rem' : '1rem', color: "#979797"}}>Country</InputLabel>
            <Select
                value={selectedCountry}
                label="Country"
                style= {{fontSize: isMobile ? '3rem' : '1rem', height: isMobile ? '7rem' : '3.5rem' }}
                onChange={(event) => handleCountryChange(event.target.value)} // Adjusted to directly set the value
              >
                {countryOptions.map((option) => (
                  <MenuItem key={option.value} value={option.value} style={itemStyle}>
                    {option.label}
                  </MenuItem>
                  
                ))}

            </Select>
            <FormHelperText>{formErrors.selectedCountry}</FormHelperText>
            </FormControl>
          </Grid>
          <Grid item xs={12} sx={{ paddingTop: '20px' }}>
          <MuiPhone
            value={muiPhone} // Control the MuiPhone with state
            onChange={(value) => setMuiPhone(value)} // Update state on change
            fullWidth
            error={!!formErrors.muiPhone} // Assuming MuiPhone accepts an error prop
            InputProps={{
              style: { fontSize: isMobile ? '3rem' : '1rem', height: isMobile ? '7rem' : '3.5rem' }, // Adjust font size and height of the input
            }}
            InputLabelProps={{
              style: { fontSize: isMobile ? '3rem' : '1rem'}, // Adjust font size and height of the label
            }}
          />

          </Grid>
          <Grid item xs={12} sx={{ paddingTop: '20px' }} >
            <TextField
              label="Email"
              fullWidth
              value={email} // Assuming you have a state for the original email
              onChange={(e) => setEmail(e.target.value)} // And a handler for setting it
              error={!!formErrors.email} // Show error state if there's an error for firstName
              helperText={formErrors.email} // Show helper text if there's an error
              InputProps={{
                style: { fontSize: isMobile ? '3rem' : '1rem', height: isMobile ? '7rem' : '3.5rem' }, // Adjust font size and height of the input
              }}
              InputLabelProps={{
                style: { fontSize: isMobile ? '3rem' : '1rem'}, // Adjust font size and height of the label
              }}
            />
          </Grid>
          <Grid item xs={12} sx={{ paddingTop: '16px' }}>
            <TextField
              label="Verify Email"
              fullWidth
              value={verifiedEmail}
              onChange={(e) => setVerifiedEmail(e.target.value)}
              error={email !== verifiedEmail && !!formErrors.verifiedEmail} // Show error if emails do not match and verifiedEmail is not empty
              helperText={email !== verifiedEmail && verifiedEmail !== '' ? 'Emails do not match' : ''} // Helper text for error
              InputProps={{
                style: { fontSize: isMobile ? '3rem' : '1rem', height: isMobile ? '7rem' : '3.5rem' }, // Adjust font size and height of the input
              }}
              InputLabelProps={{
                style: { fontSize: isMobile ? '3rem' : '1rem'}, // Adjust font size and height of the label
              }}

            />
          </Grid>
          </Box>
          
          </Grid>
          <Grid item xs={12} lg={6} sx={{ marginTop: '13px' }}> {/* Second grid for additional content */}
            <Box border={2} borderColor="#D3D3D3" borderRadius={4} p={2}sx={{boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.1)'}}>
              <Typography variant="h5" gutterBottom style={{ fontSize: isMobile ? '4rem' : '1.5rem', color:"#023e48" }}> Booking Summary</Typography>
              <Box display="flex" alignItems="center" gap={1}> {/* Adjust the gap as needed */}
              <PlaceOutlinedIcon style={iconStyles}/>
              <Typography variant="body1" sx={{ color: 'black', fontSize: isMobile ? '2rem' : '1rem'}}>Start: <span style={{ color: 'black', fontSize: isMobile ? '2.5rem' : '1rem'}}>{OriginAdress}</span></Typography>
              </Box>
              <Box display="flex" alignItems="center" gap={1}> {/* Adjust the gap as needed */}
              <PlaceOutlinedIcon style={iconStyles}/>
              <Typography variant="body1" sx={{ color: 'black', fontSize: isMobile ? '2rem' : '1rem'}}>Destination: <span style={{ color: 'black', fontSize: isMobile ? '2.5rem' : '1rem'}}>{DestinationAdress}</span></Typography>
              </Box>
              <Box display="flex" alignItems="center" gap={1}> {/* Adjust the gap as needed */}
              <CalendarMonthOutlinedIcon style={iconStyles}/>
              <Typography variant="body1" sx={{ color: 'black', fontSize: isMobile ? '2.5rem' : '1rem'}}>{Date}</Typography>
              </Box>
              <Box display="flex" alignItems="center" gap={1}> {/* Adjust the gap as needed */}
              <AccessTimeOutlinedIcon style={iconStyles}/>
              <Typography variant="body1" sx={{ color: 'black', fontSize: isMobile ? '2.5rem' : '1rem'}}>{Time}</Typography>
              </Box>
              <Box display="flex" alignItems="center" gap={1}> {/* Adjust the gap as needed */}
              <Person2OutlinedIcon style={iconStyles}/>
              <Typography variant="body1" sx={{ color: 'black', fontSize: isMobile ? '2.5rem' : '1rem'}}>{Persons}</Typography>
              </Box>
              <Box display="flex" alignItems="center" gap={1}> {/* Adjust the gap as needed */}
              <Typography variant="body1" sx={{ color: 'black', fontSize: isMobile ? '3rem' : '1rem'}}>Price:</Typography>
              <Typography variant="body1" sx={{ color: 'black', fontSize: isMobile ? '3rem' : '1.5rem'}}>{price}€</Typography>
              </Box>
            </Box>
            <Grid item xs={12} sx={{ mt: 3 }}>
            <Box border={2} borderColor="#D3D3D3" borderRadius={4} p={2} sx={{boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.1)'}}>
            <Typography variant="h5" gutterBottom style={{ fontSize: isMobile ? '4rem' : '1.5rem', color:"#023e48" }}>Additional Details</Typography>
              <FormControl sx={{ width: '100%', mb: 1 }}>
                {amenities.map((amenity, index) => {
                  const isSelected = isAmenitySelected(amenity.name); // Check if the amenity is selected
                  return (
                    <FormControlLabel
                      key={index}
                      control={
                        <Checkbox
                          checked={isSelected}
                          onChange={handleAmenityToggle(amenity.name)}
                          icon={<ListItemIcon style={{ fontSize: isMobile ? '2rem' : 'inherit' }}>{amenity.icon}</ListItemIcon>}
                          checkedIcon={<ListItemIcon style={{ fontSize: isMobile ? '2rem' : 'inherit' }}>{amenity.icon}</ListItemIcon>}
                          style={{
                            color: isSelected ? '#009000' : undefined, // Change to your preferred color when selected
                          }}
                        />
                      }
                      label={amenity.name}
                      sx={{ 
                        '& .MuiTypography-root': { 
                          fontSize: isMobile ? '2.5rem' : '1rem',
                          color: isSelected ? '#009000' : 'inherit', // Change label color when selected
                        } 
                      }} // Adjust font size here
                    />
                  );
                })}
              </FormControl>
              <TextField
                placeholder="Additional Flight/Train/PickUp Information: Flight/Train Number, Gate, Terminal, Exit...."
                InputProps={{
                  style: 
                     { // Targets only the placeholder
                      fontSize: isMobile ? '2rem' : '1rem',
                      color: 'black' // You can also style the color
                    }
                  
                }}
                fullWidth
                multiline
                rows={isMobile ? 4: 2}
                value={additionalInfo} // Control the value with state
                onChange={(e) => setAdditionalInfo(e.target.value)} // Update state on change
              />
            </Box>
          </Grid>
          </Grid>
          <Grid item xs={12} sx={{ mt: 0, display: 'flex', justifyContent: 'flex-end' }}>
              <Button 
                type="submit" 
                variant="contained" 
                color="primary" 
                style={{ backgroundColor: '#023e48', color: '#FFFFFF' }} 
                sx={{ height: isMobile ? 140 : 50 , width: isMobile ? 600 : 350 ,fontSize:isMobile ? 50 : 20  }}
              >
                Reserve Ride*
              </Button >
              {isSubmitting && <CircularProgress />}
            </Grid>
            <Grid item xs={12} sx={{ mt: 0.2 , marginBottom:-1}}>
            <Typography variant="body2" color="textSecondary" style={{ fontSize: isMobile ? '1.5rem' : '0.7rem', color:"textSecondary" }}>
              *By requesting a ride, the Driver has the possibility to contact you via Phone to resolve any issues or further confirm arrival time. Please be advised that this offer is conditional and subject to formal confirmation of the ride. The offer shall not be considered binding until such confirmation has been issued.
            </Typography>
            </Grid>
          </Grid>
      </form>
    </Box>
    
  );
};

export default BookingForm;
