import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { Grid, Button, Box, Typography } from '@mui/material';
import PlaceOutlinedIcon from '@mui/icons-material/PlaceOutlined';
import CalendarMonthOutlinedIcon from '@mui/icons-material/CalendarMonthOutlined';
import AccessTimeOutlinedIcon from '@mui/icons-material/AccessTimeOutlined';
import Person2OutlinedIcon from '@mui/icons-material/Person2Outlined';
import WhatsAppIcon from '@mui/icons-material/WhatsApp';
import EmailOutlinedIcon from '@mui/icons-material/EmailOutlined';
import { useNavigate } from 'react-router-dom';
import { useMediaQuery } from 'react-responsive';

const URL_PROXY = process.env.REACT_APP_SERVER

const SuccessBooking = ({ booking }) => {
    const isMobile = useMediaQuery({ maxWidth: 1200 });
    const navigate = useNavigate();
    const iconStyles = { fontSize: isMobile ? 45 : 25};
    const [bookingDetails, setBookingDetails] = useState({}); // Declare useState at the top level

    // Helper function to check empty data
    const isEmptyData = (data) => {
        return data && Object.keys(data).length === 0;
    };

    // useEffect for redirecting if no booking data
    useEffect(() => {
        if (!booking || isEmptyData(booking)) {
            console.log('No or empty booking data available, navigating to home.');
            navigate('/');
        }
    }, [booking, navigate]);

    // useEffect for fetching booking details
    useEffect(() => {
        if (!booking || isEmptyData(booking)) {
            return; // Early return if no booking data to prevent further execution
        }

        const fetchBookingDetails = async () => {
            const url = `${URL_PROXY}/getBooking`;
            const key = process.env.REACT_APP_DATABASE_KEY;
            const data = { bookingId: booking.bookingId, key };
            try {
                const response = await axios.post(url, data);
                console.log('Booking retrieved:', response.data);
                setBookingDetails(response.data.booking);
            } catch (error) {
                console.error('Error retrieving booking:', error.response?.data || error.message);
                alert('An internal server error occurred. The Booking failed !.');
            }
        };

        fetchBookingDetails();
    }, [booking]); // Dependency on booking

    // If there's no booking data, return loading state
    if (!booking || isEmptyData(booking)) {
        return <div>Loading booking details or no booking available...</div>;
    }

    const { firstName, lastName, adress, country, phonenumber, email, origin, destination, persons, date, time, price, _id } = bookingDetails;


    const phoneNumber = "+1234567890"; 
    const emailAddress = "example@example.com"; 

    const copyToClipboard = (text) => {
      const textarea = document.createElement("textarea");
      textarea.value = text;
      document.body.appendChild(textarea);
      textarea.select();
      document.execCommand("copy");
      document.body.removeChild(textarea);
    };
  
    const handleWhatsAppClick = () => {
      copyToClipboard(phoneNumber);
      alert("Phone number: "+phoneNumber+" copied to clipboard!");
      // Add any additional logic you want to handle when WhatsApp button is clicked
    };

    const handleEmailClick = () => {
        copyToClipboard(emailAddress);
        alert("Email: "+emailAddress+" copied to clipboard!");
        // Add any additional logic you want to handle when Email button is clicked
      };


    const handleButtonClick = () => {
        navigate('/');  // Replace '/target-path' with your desired route
    };

    return (
        <div className="Success">
            <Box display="flex" flexDirection="column" justifyContent="center" alignItems="center" marginTop={10}>
            <Typography variant="h4" color="#023e48" gutterBottom style={{ fontSize: isMobile ? '4rem' : '3rem', color:"#023e48" , padding: isMobile ? '2rem' : '0rem' ,marginLeft: isMobile ? '5rem' : '0rem'} }>
                Thank you for choosing Moroder Taxi!
            </Typography>
            <Typography variant="h6" color="#023e48" gutterBottom style={{ fontSize: isMobile ? '2rem' : '1.5rem', color:"#023e48" , padding: isMobile ? '2rem' : '0rem'}}>
                A confirmation Email has been send!
            </Typography>
            <Box display="flex" justifyContent="center">
                    <Button onClick={handleWhatsAppClick} variant="contained" size= {isMobile ? "large" : "small"} sx={{ backgroundColor: "#25D366", color: "#fff", marginRight: 3, marginTop: 3  }}>
                        <WhatsAppIcon sx={{ fontSize: 25 }} />
                    </Button>
                    <Button onClick={handleEmailClick} variant="contained" size= {isMobile ? "large" : "small"} sx={{ backgroundColor: "#34B7F1", color: "#fff",marginTop: 3  }}>
                        <EmailOutlinedIcon sx={{ fontSize: 25 }} />
                    </Button>
            </Box>
            </Box>
             <Grid container justifyContent="center" sx={{ maxWidth: '80%', margin: '0 auto', marginTop: "0.3rem" }}>
            <Grid item xs={12} lg={6} sx={{ marginTop: '13px' }}> {/* Second grid for additional content */}
            <Box border={2} borderColor="#D3D3D3" borderRadius={4} p={2}sx={{boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.1)'}}>
            <Typography variant="h5" gutterBottom style={{ fontSize: isMobile ? '3rem' : '1.5rem', color:"#023e48" }}> Booking Summary</Typography>
              <Box display="flex" alignItems="center" gap={1}> {/* Adjust the gap as needed */}
              <PlaceOutlinedIcon style={iconStyles}/>
              <Typography variant="body1" sx={{ color: 'black', fontSize: isMobile ? '2rem' : '1rem'}}>Start: <span style={{ color: 'black', fontSize: isMobile ? '2.5rem' : '1.2rem'}}>{origin}</span></Typography>
              </Box>
              <Box display="flex" alignItems="center" gap={1}> {/* Adjust the gap as needed */}
              <PlaceOutlinedIcon style={iconStyles}/>
              <Typography variant="body1" sx={{ color: 'black', fontSize: isMobile ? '2rem' : '1rem'}}>Destination: <span style={{ color: 'black', fontSize: isMobile ? '2.5rem' : '1.2rem'}}>{destination}</span></Typography>
              </Box>
              <Box display="flex" alignItems="center" gap={1}> {/* Adjust the gap as needed */}
              <CalendarMonthOutlinedIcon style={iconStyles}/>
              <Typography variant="body1" sx={{ color: 'black', fontSize: isMobile ? '2.5rem' : '1rem'}}>{date}</Typography>
              </Box>
              <Box display="flex" alignItems="center" gap={1}> {/* Adjust the gap as needed */}
              <AccessTimeOutlinedIcon style={iconStyles}/>
              <Typography variant="body1" sx={{ color: 'black', fontSize: isMobile ? '2.5rem' : '1rem'}}>{time}</Typography>
              </Box>
              <Box display="flex" alignItems="center" gap={1}> {/* Adjust the gap as needed */}
              <Person2OutlinedIcon style={iconStyles}/>
              <Typography variant="body1" sx={{ color: 'black', fontSize: isMobile ? '2.5rem' : '1rem'}}>{persons}</Typography>
              </Box>
              <Box display="flex" alignItems="center" gap={1}> {/* Adjust the gap as needed */}
              <Typography variant="body1" sx={{ color: 'black', fontSize: isMobile ? '2.5rem' : '1rem'}}>Total:</Typography>
              <Typography variant="body1" sx={{ color: 'black', fontSize: isMobile ? '4rem' : '1.5rem'}}>{price}€</Typography>
              </Box>
            </Box>
            </Grid>
            </Grid>
            <Grid container justifyContent="center" sx={{ maxWidth: '80%', margin: '0 auto', marginTop: "2rem" }}>
            <Grid item xs={12} lg={6} sx={{ marginTop: '13px' }}> {/* Second grid for additional content */}
            <Box border={2} borderColor="#D3D3D3" borderRadius={4} p={2}sx={{boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.1)'}}>
            <Box display="flex" justifyContent="space-between" alignItems="center">
                <Typography variant="h5" gutterBottom sx={{ color: '#023e48', fontSize: isMobile ? '2rem' : '1.5rem'}}>
                    Booking Details
                </Typography>
                <Typography variant="h5" sx={{ color: 'black', fontSize: '0.9rem' }}>
                   ID:{_id}
                </Typography>
            </Box>
              <Box display="flex" alignItems="center" gap={1}> {/* Adjust the gap as needed */}
              <Typography variant="body1" sx={{ color: 'black', fontSize: isMobile ? '2rem' : '0.9rem'}}>{firstName} {lastName}</Typography>
              </Box>
              <Box display="flex" alignItems="center" gap={1}> {/* Adjust the gap as needed */}
              <Typography variant="body1" sx={{ color: 'black', fontSize: isMobile ? '2rem' : '0.9rem'}}>{adress}</Typography>
              </Box>
              <Box display="flex" alignItems="center" gap={1}> {/* Adjust the gap as needed */}
              <Typography variant="body1" sx={{ color: 'black', fontSize: isMobile ? '2rem' : '0.9rem'}}>{country}</Typography>
              </Box>
              <Box display="flex" alignItems="center" gap={1}> {/* Adjust the gap as needed */}
              <Typography variant="body1" sx={{ color: 'black', fontSize: isMobile ? '2rem' : '0.9rem'}}>{phonenumber}</Typography>
              </Box>
              <Box display="flex" alignItems="center" gap={1}> {/* Adjust the gap as needed */}
              <Typography variant="body1" sx={{ color: 'black', fontSize: isMobile ? '2rem' : '0.9rem'}}>{email}</Typography>
              </Box>
            </Box>
            <Box display="flex" justifyContent="center" m={4} >  {/* Adding margin for better spacing */}
                <Button variant="contained"  onClick={handleButtonClick} sx={{backgroundColor: "#023e48", height: isMobile ? 140 : 50 , width: isMobile ? 600 : 200 ,fontSize:isMobile ? 40 : 20  }}>
                    Home
                </Button>
            </Box>
            </Grid>
            </Grid>
        </div>
    );
}

export default SuccessBooking;