import WhatsAppIcon from '@mui/icons-material/WhatsApp';
import Grid from "@mui/material/Grid";
import EmailOutlinedIcon from '@mui/icons-material/EmailOutlined';
import Button from '@mui/material/Button';
import { Element } from 'react-scroll';
import { useMediaQuery } from 'react-responsive';


const Contact = ({ id }) => {

    const isMobile = useMediaQuery({ maxWidth: 1200 });
    const phoneNumber = "+3934229118790"; 
    const emailAddress = "info@taximoroder.it"; 

    const copyToClipboard = (text) => {
      const textarea = document.createElement("textarea");
      textarea.value = text;
      document.body.appendChild(textarea);
      textarea.select();
      document.execCommand("copy");
      document.body.removeChild(textarea);
    };
  
    const handleWhatsAppClick = () => {
      copyToClipboard(phoneNumber);
      alert("Phone number: "+phoneNumber+" copied to clipboard!");
      // Add any additional logic you want to handle when WhatsApp button is clicked
    };

    const handleEmailClick = () => {
        copyToClipboard(emailAddress);
        alert("Email: "+emailAddress+" copied to clipboard!");
        // Add any additional logic you want to handle when Email button is clicked
      };

    return (  
        <Element name={id}>   
        <div  className="ContainerContact">
            <div className="ContactConatiner">
                <div className="PageHeaderConatct">
                    <p style={{ fontSize: isMobile ? '3rem' : '2rem' }}>Contact</p>
                </div>
                <div className="Contact">
                    <p className ="text" style={{ fontSize: isMobile ? '3.5rem' : '3rem' }}>CONTACT</p>
                    <p className="us" style={{ fontSize: isMobile ? '3.5rem' : '3rem' }}>US</p>
                    <div className = "Strich" ></div>
                </div> 
                <div className="Info">
                    <p className='Name' style={{ fontSize: isMobile ? '3rem' : '1.5rem' }}>Taxi Moroder</p>
                    <p className="Adress" style={{ fontSize: isMobile ? '2rem' : '1.2rem' }}>Via Val 1</p>
                    <p className="Adress" style={{ fontSize: isMobile ? '2rem' : '1.2rem' }}>39047 S.Cristina - BZ</p>
                    <p className="Adress" style={{ fontSize: isMobile ? '2rem' : '1.2rem' }}>+3934229118790</p>
                    <p className="Adress" style={{ fontSize: isMobile ? '2rem' : '1.2rem' }}>info@taximoroder.it</p>
                    <Grid container={true} columns={2} spacing={1} sx={{ color: "text.primary", justifyContent: "center" }}>
                    <Grid item>
                        <Button onClick={handleWhatsAppClick} variant="contained"  size={isMobile ? "large" : "small"} style={{ backgroundColor: "#25D366", color: "#fff", marginTop: isMobile ? "1rem" : "0rem" }}>
                            <WhatsAppIcon style={{ fontSize: 35 }} />
                        </Button>
                     </Grid>
                    <Grid item>
                    <Button onClick={handleEmailClick} variant="contained"  size={isMobile ? "large" : "small"} style={{ backgroundColor: "#34B7F1", color: "#fff", marginTop: isMobile ? "1rem" : "0rem" }}>
                        <EmailOutlinedIcon style={{ fontSize: 35 }} />
                    </Button>
                    </Grid>
                </Grid>  
                </div>
            </div>
        </div>
        </Element>
          )
          }
    
    
    export default Contact;