import { Element } from 'react-scroll'; // Import Element from react-scroll
import AirportShuttleOutlinedIcon from '@mui/icons-material/AirportShuttleOutlined';
import ConnectingAirportsOutlinedIcon from '@mui/icons-material/ConnectingAirportsOutlined';
import StarBorderOutlinedIcon from '@mui/icons-material/StarBorderOutlined';
import LandscapeOutlinedIcon from '@mui/icons-material/LandscapeOutlined';
import TimelineOutlinedIcon from '@mui/icons-material/TimelineOutlined';
import { useTranslation } from 'react-i18next';
import { useMediaQuery } from 'react-responsive';


const textright = "The Moroder Shuttle Service was founded in 2008. Since then, we have completed countless rides and earned the satisfaction of numerous customers. We are dedicated to providing the best possible experience for our customers.  ";
const textleft = "Safety, cleanliness, professionalism, reliability, and discretion are of utmost importance for us.";





const AboutUs = ({id}) => {
  const { t } = useTranslation();
  const isMobile = useMediaQuery({ maxWidth: 1200 });
  const listItemStyle = { fontSize: isMobile ? 30 : 20, Padding: isMobile ? 30 : 20};
  const listHeaderStyle = { fontSize: isMobile ? 45 : 25  ,color:"#023e48"};
  const iconStyles = { fontSize: isMobile ? 45 : 25, marginRight: isMobile ? 20 : 10 };// Adjust size as needed

  return (
    <Element name={id}> {/* Wrap the entire content with Element and assign name prop */}
      <div  className="ContainerAbteil">
        <div className="ok">
          <div className="PageHeader">
            <p>{t('about_us_header')}</p>
          </div>
          <div className="aboutUstext1">
            <p>{textleft}</p>
          </div>
          <div className="aboutUstext2">
            <p>{textright}</p>    
          </div>
          <div className="servicesList">
            <h3 style={listHeaderStyle}>Services :</h3> {/* Header for the services section */}
            <ul>
      <li style={listItemStyle}> 
        <ConnectingAirportsOutlinedIcon style={iconStyles}/> Airport Shuttle
      </li>
      <li style={listItemStyle}>
        <StarBorderOutlinedIcon style={iconStyles}/> VIP Shuttle
      </li>
      <li style={listItemStyle}>
        <LandscapeOutlinedIcon style={iconStyles}/> Day Trips
      </li >
      <li style={listItemStyle}>
        <TimelineOutlinedIcon style={iconStyles} /> Point-to-Point Trips
      </li>
      <li style={listItemStyle}>
        <AirportShuttleOutlinedIcon style={iconStyles} /> Special Requests
      </li>
    </ul>
          </div>
        </div>
      </div>
    </Element>
  );
};

export default AboutUs;