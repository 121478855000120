import React, { useState } from 'react';
import { faChevronDown, faChevronUp } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Element } from 'react-scroll';
import { useMediaQuery } from 'react-responsive';


const Faq = ({ id }) => {
  const isMobile = useMediaQuery({ maxWidth: 1200 });
  const [faqData, setFaqData] = useState([
    {
      question: 'How do we meet at the airport?',
      answer: 'The driver will meet you with a name tag at the airport gate.',
      open: false,
    },
    {
      question: 'Is a phone number required?',
      answer: 'Yes, a phone number is required due to potential unexpected delays on either the driver’s or passenger’s side.',
      open: false,
    },
    {
      question: 'Which car is used?',
      answer: 'All cars used are 2022 Mercedes V-Class.',
      open: false,
    },
    {
      question: 'What are the payment options?',
      answer: 'The following payment options are available: cash, card, or bank transfer for large payments.',
      open: false,
    },
    {
      question: 'Do I pay extra for ski luggage?',
      answer: 'No, all luggage is included in the ride.',
      open: false,
    },
    {
      question: 'When do i pay?',
      answer: ' The transaction can be done either before the ride or after the ride.',
      open: false,
    },
    {
      question: 'Do you have child seats?',
      answer: 'Yes, child seats are included. Just select the option when booking.',
      open: false,
    },
    {
      question: 'What happens if my flight is delayed?',
      answer: 'A delay of up to 1 hour is included in the ride. Further delays incur a fee of 60 Euro per hour.',
      open: false,
    },
    {
      question: 'When does the car stop for rest breaks?',
      answer: 'The car stops for rest breaks after 2 hours of travel time, unless there are unexpected health concerns.',
      open: false,
    },
    {
      question: 'Which languages does the driver speak?',
      answer: 'The drivers speak English, German, and Italian fluently.',
      open: false,
    },
    ]);



    const toggleAccordion = (index) => {
        setFaqData((prevFaqData) =>
          prevFaqData.map((item, i) =>
            i === index ? { ...item, open: !item.open } : { ...item, open: false }
          )
        );
      };
    
      return (
        <Element name={id}>
        <div  className="ContainerFaq">
          <div className="SubContainerFaq">
            <div className="PageHeaderFaq">
              <p style={{ fontSize: isMobile ? '3rem' : '2rem' }}>FAQ</p>
            </div>
            <div className="FaqTextContainer">
                <p className='Title' style={{ fontSize: isMobile ? '4rem' : '3rem' }}>FAQ</p>
              {faqData.map((faqItem, index) => (
                <div key={index} className="faq-item">
                  <button className="accordion" onClick={() => toggleAccordion(index)}>
                    <p className="Question" style={{ fontSize: isMobile ? '2rem' : '1rem' }}>{faqItem.question}</p>
                    <FontAwesomeIcon icon={faqItem.open ? faChevronUp : faChevronDown} />
                  </button>
                  {faqItem.open && (
                    <div className="panel">
                      <p className="Answer" style={{ fontSize: isMobile ? '2rem' : '1rem' }}>{faqItem.answer}</p>
                    </div>
                  )}
                </div>
              ))}
            </div>
          </div>
        </div>
        </Element>
      );
    };
    
    export default Faq;